import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectStateService} from '../../services/project-state.service';

@Component({
  selector: 'app-main-anti-stress',
  templateUrl: './main-anti-stress.component.html',
  styleUrls: ['./main-anti-stress.component.scss']
})
export class MainAntiStressComponent implements OnInit {

  public content: any;
  public restartLink = this.projectState.pathAntiStress;
  public currentSectionNumber = '1';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly projectState: ProjectStateService,
  ) {}

  ngOnInit(): void {
    this.content = this.activatedRoute.snapshot.data.content;

    this.activatedRoute.queryParams.subscribe(({section}) => {
      this.currentSectionNumber = section || '1';
    });
  }

  public onAnswer(answer: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {section: answer.nextSection},
      queryParamsHandling: 'merge'
    });
  }

}
