<app-header [title]="content.header.title"
            [imageName]="imageName || content.header.imageName"
            [restartLabel]="content.header.restartLabel"
            [restartLink]="restartLink"
            [isRestartLinkVisible]="currentSectionNumber > 1"></app-header>

<app-progress-bar *ngIf="currentSectionNumber > 0"
                  [max]="content.sections.length - 1"
                  [current]="currentSectionNumber"></app-progress-bar>

<app-sections [sections]="content.sections"
              [currentSectionNumber]="currentSectionNumber"
              [options]="options"
              (answer)="onAnswer($event)"></app-sections>
