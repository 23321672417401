<tkui-header-color-image
  [title]="title"
  [imageName]="imageName"
  [breadcrumbTitles]="restartLabel"
  [breadcrumbPath]="restartLink"
  *ngIf="isRestartLinkVisible"
></tkui-header-color-image>

<tkui-header-color-image
  [title]="title"
  [imageName]="imageName"
  *ngIf="!isRestartLinkVisible"
></tkui-header-color-image>
