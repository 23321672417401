import {Injectable} from '@angular/core';
import {QuestionModel} from '../models/question.model';
import {ErrorService} from './error.service';
import {Observable, Subject} from 'rxjs';

const QUESTION_ID = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
  Q5: 'Q5',
  Q6: 'Q6',
  Q7: 'Q7',
  Q8: 'Q8',
  Q9: 'Q9',
  Q10: 'Q10',
  Q11: 'Q11',
  Q12: 'Q12',
};

export const ANSWER_OPTION = {
  Q1_Aa: 'Q1_Aa',
  Q1_Ab: 'Q1_Ab',
  Q1_Ac: 'Q1_Ac',
  Q1_Ad: 'Q1_Ad',

  Q2_Aa: 'Q2_Aa',
  Q2_Ab: 'Q2_Ab',
  Q2_Ac: 'Q2_Ac',
  Q2_Ad: 'Q2_Ad',

  Q3_Aa: 'Q3_Aa',
  Q3_Ab: 'Q3_Ab',
  Q3_Ac: 'Q3_Ac',
  Q3_Ad: 'Q3_Ad',

  Q4_Aa: 'Q4_Aa',
  Q4_Ab: 'Q4_Ab',
  Q4_Ac: 'Q4_Ac',

  Q5_Aa: 'Q5_Aa',
  Q5_Ab: 'Q5_Ab',
  Q5_Ac: 'Q5_Ac',

  Q6_Aa: 'Q6_Aa',
  Q6_Ab: 'Q6_Ab',
  Q6_Ac: 'Q6_Ac',

  Q7_Aa: 'Q7_Aa',
  Q7_Ab: 'Q7_Ab',
  Q7_Ac: 'Q7_Ac',

  Q8_Aa: 'Q8_Aa',
  Q8_Ab: 'Q8_Ab',
  Q8_Ac: 'Q8_Ac',

  Q9_Aa: 'Q9_Aa',
  Q9_Ab: 'Q9_Ab',

  Q10_Aa: 'Q10_Aa',
  Q10_Ab: 'Q10_Ab',
  Q10_Ac: 'Q10_Ac',

  Q11_Aa: 'Q11_Aa',
  Q11_Ab: 'Q11_Ab',
  Q11_Ac: 'Q11_Ac',
};

enum OBSTACLE_PRE_CONDITION {
  A_LOT,
  A_LITTLE,
}

export enum OBSTACLE {
  MOTIVATION,
  TIME,
}

export enum ACTIVITY {
  UNDETERMINED,
  WALKING,
  CYCLING,
  RUNNING
}

enum COMMITMENT_PRE_CONDITION {
  RELAXED,
  AMBITIOUS,
  MID
}

export enum COMMITMENT {
  RELAXED,
  AMBITIOUS
}


@Injectable({
  providedIn: 'root'
})
export class AntiSedentaryEvaluationService {

  private readonly changes: Subject<void> = new Subject<void>();
  private readonly questions = QuestionModel.createBunch(Object.values(QUESTION_ID));

  constructor(private readonly errorService: ErrorService) { }

  public get changes$(): Observable<void> {
    return this.changes.asObservable();
  }

  public applyAnswer(answerId: string): boolean {
    const isValidAnswer: boolean = Object.keys(ANSWER_OPTION).find((possibleAnswer) => (possibleAnswer === answerId)) ? true : false;

    if (!isValidAnswer) {
      this.errorService.logDevWarning('Invalid answer: ' + answerId);
      return false;
    }

    const questionId = answerId.split('_')[0];
    const question = this.getQuestionById(questionId);

    if (question) {
      question.answerState = answerId;
    } else {
      this.errorService.logDevWarning('Question not found: ' + questionId);
      return false;
    }

    this.changes.next();
    return true;
  }

  public reset() {
    this.questions.forEach(q => q.reset());
    this.changes.next();
  }

  public isQuestionAnswered(questionId): boolean {
    const q = this.getQuestionById(questionId);
    try {
      return q.isAnswered;
    } catch (err) {
      this.errorService.logDevWarning('Question not found: ' + questionId);
      return false;
    }
  }

  public get obstaclePreCondition() {

    let result;

    switch (true) {
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Aa):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ab):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ac):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ad):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Aa):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ab):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ac):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ad):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Aa):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ab):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ac):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ad):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ad) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Aa):
        result = OBSTACLE_PRE_CONDITION.A_LOT;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ad) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ab):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ad) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ac):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q1_Ad) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q2_Ad):
        result = OBSTACLE_PRE_CONDITION.A_LITTLE;
        break;
    }

    return result;

  }

  public get obstacle(): OBSTACLE {
    const precondition = this.obstaclePreCondition;
    let result;

    if (this.hasBeenAnsweredWith(ANSWER_OPTION.Q3_Aa) ||
        this.hasBeenAnsweredWith(ANSWER_OPTION.Q3_Ab) ||
        this.hasBeenAnsweredWith(ANSWER_OPTION.Q3_Ac) ||
        this.hasBeenAnsweredWith(ANSWER_OPTION.Q3_Ad)) {

      if (precondition === OBSTACLE_PRE_CONDITION.A_LOT) {
        result = OBSTACLE.TIME;
      }

      if (precondition === OBSTACLE_PRE_CONDITION.A_LITTLE) {
        result = OBSTACLE.MOTIVATION;
      }

    }

    return result;
  }

  public get activity(): ACTIVITY {

    const preconditionAllRelevantQuestionsAnswered: boolean = [QUESTION_ID.Q4, QUESTION_ID.Q5, QUESTION_ID.Q6, QUESTION_ID.Q7, QUESTION_ID.Q8]
      .reduce((accumulator: boolean, currentValue: string): boolean => (this.isQuestionAnswered(currentValue) && accumulator), true);

    if (!preconditionAllRelevantQuestionsAnswered) {
      return ACTIVITY.UNDETERMINED;
    }

    const activities = [
      {
        id: ACTIVITY.WALKING,
        length: [
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q4_Ab),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q5_Ab),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q6_Ac),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q7_Ab),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q8_Ac),
        ].filter(item => item).length
      },
      {
        id: ACTIVITY.CYCLING,
        length: [
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q4_Ac),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q5_Aa),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q6_Aa),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q7_Ac),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q8_Aa),
        ].filter(item => item).length
      },
      {
        id: ACTIVITY.RUNNING,
        length: [
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q4_Aa),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q5_Ac),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q6_Ab),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q7_Aa),
          this.hasBeenAnsweredWith(ANSWER_OPTION.Q8_Ab),
        ].filter(item => item).length
      },
    ].sort((a, b) => {
      return a.length - b.length;
    });

    return activities.pop().id;

  }


  public get commitment(): COMMITMENT {

    let precondition: COMMITMENT_PRE_CONDITION;
    let result: COMMITMENT;

    switch (true) {
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Aa):
        precondition = COMMITMENT_PRE_CONDITION.RELAXED;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ab):
        precondition = COMMITMENT_PRE_CONDITION.RELAXED;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Aa) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ac):
        precondition = COMMITMENT_PRE_CONDITION.RELAXED;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Aa):
        precondition = COMMITMENT_PRE_CONDITION.RELAXED;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ab):
        precondition = COMMITMENT_PRE_CONDITION.MID;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ab) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ac):
        precondition = COMMITMENT_PRE_CONDITION.AMBITIOUS;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Aa):
        precondition = COMMITMENT_PRE_CONDITION.AMBITIOUS;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ab):
        precondition = COMMITMENT_PRE_CONDITION.AMBITIOUS;
        break;
      case this.hasBeenAnsweredWith(ANSWER_OPTION.Q10_Ac) && this.hasBeenAnsweredWith(ANSWER_OPTION.Q11_Ac):
        precondition = COMMITMENT_PRE_CONDITION.AMBITIOUS;
        break;
    }

    if (precondition === COMMITMENT_PRE_CONDITION.RELAXED) {
      result = COMMITMENT.RELAXED;
    } else if (precondition === COMMITMENT_PRE_CONDITION.AMBITIOUS) {
      result = COMMITMENT.AMBITIOUS;
    } else if (precondition === COMMITMENT_PRE_CONDITION.MID && this.hasBeenAnsweredWith(ANSWER_OPTION.Q9_Aa)) {
      result = COMMITMENT.RELAXED;
    } else if (precondition === COMMITMENT_PRE_CONDITION.MID && this.hasBeenAnsweredWith(ANSWER_OPTION.Q9_Ab)) {
      result = COMMITMENT.AMBITIOUS;
    }

    return result;
  }


  public get linkOption(): string {
    let result: string;
    result = this.isRelaxed && this.isCycling && this.isObstacleTime ? 'linkOption1' : result;
    result = this.isRelaxed && this.isCycling && this.isObstacleMotivation ? 'linkOption2' : result;
    result = this.isRelaxed && this.isRunning && this.isObstacleTime ? 'linkOption3' : result;
    result = this.isRelaxed && this.isRunning && this.isObstacleMotivation ? 'linkOption4' : result;
    result = this.isRelaxed && this.isWalking && this.isObstacleTime ? 'linkOption5' : result;
    result = this.isRelaxed && this.isWalking && this.isObstacleMotivation ? 'linkOption6' : result;
    result = this.isAmbitious && this.isCycling && this.isObstacleTime ? 'linkOption7' : result;
    result = this.isAmbitious && this.isCycling && this.isObstacleMotivation ? 'linkOption8' : result;
    result = this.isAmbitious && this.isRunning && this.isObstacleTime ? 'linkOption9' : result;
    result = this.isAmbitious && this.isRunning && this.isObstacleMotivation ? 'linkOption10' : result;
    result = this.isAmbitious && this.isWalking && this.isObstacleTime ? 'linkOption11' : result;
    result = this.isAmbitious && this.isWalking && this.isObstacleMotivation ? 'linkOption12' : result;
    return result;
  }

  public get activityTextOption(): string {
    const activity = this.activity;
    return {
      [ACTIVITY.CYCLING]: 'textOptionCycling',
      [ACTIVITY.WALKING]: 'textOptionWalking',
      [ACTIVITY.RUNNING]: 'textOptionRunning',
    }[this.activity];
  }

  public get activityImageOption(): string {
    const activity = this.activity;
    return {
      [ACTIVITY.CYCLING]: 'imageNameCycling',
      [ACTIVITY.WALKING]: 'imageNameWalking',
      [ACTIVITY.RUNNING]: 'imageNameRunning',
    }[this.activity];
  }

  private getQuestionById(questionId: string): QuestionModel {
    return this.questions.find(q => q.questionId === questionId);
  }

  private hasBeenAnsweredWith(answerId: string): boolean {
    return this.questions.find(q => q.isTheAnswer(answerId)) ? true : false;
  }

  private get isRelaxed(): boolean {
    return this.commitment === COMMITMENT.RELAXED;
  }

  private get isAmbitious(): boolean {
    return this.commitment === COMMITMENT.AMBITIOUS;
  }

  private get isCycling(): boolean {
    return this.activity === ACTIVITY.CYCLING;
  }

  private get isRunning(): boolean {
    return this.activity === ACTIVITY.RUNNING;
  }

  private get isWalking(): boolean {
    return this.activity === ACTIVITY.WALKING;
  }

  private get isObstacleTime(): boolean {
    return this.obstacle === OBSTACLE.TIME;
  }

  private get isObstacleMotivation(): boolean {
    return this.obstacle === OBSTACLE.MOTIVATION;
  }

}
