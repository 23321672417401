import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ErrorService} from './error.service';

export enum Project {
  UNDETERMINED = 0,
  STRESS_NAVIGATOR = 1,
  RAUS_UND_LOS = 2,
}

@Injectable({
  providedIn: 'root'
})
export class ProjectStateService implements CanActivate, Resolve<any> {

  public static PROJECT_SUBDOMAIN = {
    STRESS_NAVIGATOR: 'stresstest',
    RAUS_UND_LOS: 'rausundlos'
  };

  public static PROJECT_BASEPATH = {
    STRESS_NAVIGATOR: 'stress-navigator',
    RAUS_UND_LOS: 'raus-und-los'
  };

  constructor(
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly titleService: Title,
    private readonly errorService: ErrorService,
    @Inject('Window') private readonly window: Window,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const isRoot = (state.url === '/');
    const pathIncludesStressnavigator = state.url.includes(ProjectStateService.PROJECT_BASEPATH.STRESS_NAVIGATOR);
    const pathIncludesRausundlos = state.url.includes(ProjectStateService.PROJECT_BASEPATH.RAUS_UND_LOS);
    const activeProject = this.activeProject;

    if (isRoot && activeProject === Project.STRESS_NAVIGATOR) {
      return this.router.parseUrl(this.pathAntiStress);
    }

    if (isRoot && activeProject === Project.RAUS_UND_LOS) {
      return this.router.parseUrl(this.pathAntiSedentary);
    }

    if (pathIncludesStressnavigator && activeProject !== Project.STRESS_NAVIGATOR && activeProject !== Project.UNDETERMINED) {
      return this.router.parseUrl('/');
    }

    if (pathIncludesRausundlos && activeProject !== Project.RAUS_UND_LOS && activeProject !== Project.UNDETERMINED) {
      return this.router.parseUrl('/');
    }

    return true;
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const path = state.url.split('?')[0]; // cut params
    const promise = this.httpClient.get(`./assets/content${path}/content.json`).toPromise();
    promise.then((result: any) => {
      if (result.pageTitle) {
        this.titleService.setTitle(result.pageTitle);
      } else {
        this.errorService.logDevWarning('No page title defined!');
      }
    });
    return promise;
  }

  public get activeProject(): Project {
    const host = this.window.location.host;
    let project: Project = Project.UNDETERMINED;

    switch (true) {
      case (host.indexOf(ProjectStateService.PROJECT_SUBDOMAIN.STRESS_NAVIGATOR) === 0):
        project = Project.STRESS_NAVIGATOR;
        break;
      case (host.indexOf(ProjectStateService.PROJECT_SUBDOMAIN.RAUS_UND_LOS) === 0):
        project = Project.RAUS_UND_LOS;
        break;
    }

    return project;
  }

  public get tableOfContent(): any[] {
    const tableOfContent = [];

    if (this.activeProject === Project.STRESS_NAVIGATOR || this.activeProject === Project.UNDETERMINED) {
      tableOfContent.push({
        label: 'Stress-Navigator',
        href: this.pathAntiStress,
        disabled: false
      });
    }

    if (this.activeProject === Project.RAUS_UND_LOS || this.activeProject === Project.UNDETERMINED) {
      tableOfContent.push({
        label: 'Raus & Los',
        href: this.pathAntiSedentary,
        disabled: false
      });
    }

    if (this.activeProject === Project.RAUS_UND_LOS || this.activeProject === Project.UNDETERMINED) {
      tableOfContent.push({
        label: 'Startseite TK.de',
        href: 'https://tk.de',
        disabled: false
      });
    }

    return tableOfContent;
  }

  public get pathAntiSedentary(): string {
    return `/de/${ProjectStateService.PROJECT_BASEPATH.RAUS_UND_LOS}`;
  }

  public get pathAntiStress(): string {
    return `/de/${ProjectStateService.PROJECT_BASEPATH.STRESS_NAVIGATOR}`;
  }

}
