export class QuestionModel {

  public answerState: string;

  public static createBunch(questionIds: string[]) {
    return questionIds.map(id => {
      return new QuestionModel(id);
    });
  }

  constructor(public questionId: string) {}

  public isTheAnswer(expectedAnswerOption: string): boolean {
    return (this.answerState === expectedAnswerOption);
  }

  public get isAnswered(): boolean {
    return this.answerState ? true : false;
  }

  public reset() {
    this.answerState = null;
  }

}
