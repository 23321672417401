import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ScrollToUtility} from 'tk-ui-components';
import {SectionInterface} from './section.interface';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnChanges {

  @Input() sections: SectionInterface[] | any;
  @Input() currentSectionNumber: number | string;
  @Input() options: any = {};

  @Output() answer: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly http: HttpClient,
              private readonly errorService: ErrorService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentSectionNumber) {
      ScrollToUtility.scrollTo('#question', 500, -100);
    }
  }

  public get currentSection(): SectionInterface {
    if (!this.sections[this.currentSectionNumber]) {
      this.errorService.fatalError();
    }

    return this.sections[this.currentSectionNumber];
  }

  public get isQuestion(): boolean {
    return this.currentSection.type !== 'textOption';
  }

  public get textOption(): string {
    const optionKey = this.options[this.currentSection.id];
    const optionText = this.currentSection[optionKey];

    return optionText || this.currentSection.text;
  }

  public getAnswerLinkOption(answer): string {
    const optionKey = this.options[answer.id];
    const optionLink = answer[optionKey];

    return optionLink || answer.link;
  }

  public onClickAnswer(answer): void {
    this.answer.emit(answer);
  }
}
