import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {SectionsComponent} from './components/sections/sections.component';
import {NotFoundPageComponent} from './components/not-found-page/not-found-page.component';
import {TkUiComponentsModule} from 'tk-ui-components';
import { HomePageComponent } from './components/home-page/home-page.component';
import { MainAntiStressComponent } from './components/main-anti-stress/main-anti-stress.component';
import { MainAntiSedentaryComponent } from './components/main-anti-sedentary/main-anti-sedentary.component';
import { HeaderComponent } from './components/header/header.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    HeaderComponent,
    HomePageComponent,
    MainAntiSedentaryComponent,
    MainAntiStressComponent,
    NotFoundPageComponent,
    ProgressBarComponent,
    SectionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TkUiComponentsModule,
  ],
  providers: [
    {provide: 'Window', useValue: window}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
