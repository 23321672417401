import {Component} from '@angular/core';
import {LinkListItemInterface} from 'tk-ui-components/lib/link-list/link-list-item.interface';
import {ProjectStateService} from '../../services/project-state.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent {

  public tableOfContent: LinkListItemInterface[] = this.projectState.tableOfContent;
  constructor(private readonly projectState: ProjectStateService) {}

}
