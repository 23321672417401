import {Injectable, isDevMode} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private readonly router: Router) { }

  fatalError() {
    this.router.navigateByUrl('/ein-fehler-ist-aufgetreten');
  }

  logDevWarning(msg: string) {
    if (isDevMode()) {
      console.warn(msg);
    }
  }


}
