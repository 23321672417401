import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() title: any;
  @Input() imageName: string;
  @Input() restartLabel: string;
  @Input() restartLink: string;
  @Input() isRestartLinkVisible: boolean;

}
