<main class="sections">

  <div role="region"
       aria-live="assertive"
       id="questionContainer">

      <ng-container *ngIf="isQuestion">
        <div class="grid-root-container">
          <div class="section standard-grid-item">
            <h4 class="question" id="question" role="label">{{currentSection.question}}</h4>
            <div role="listbox" aria-labelledby="question">
              <div class="answer"
                   role="option"
                   *ngFor="let answer of currentSection.answers; let index = index">

                <a class="link"
                   [href]="getAnswerLinkOption(answer)"
                   tkuiKeyboardAccess
                   *ngIf="answer.link">
                  <ng-container *ngTemplateOutlet="innerButton; context:{answer: answer, index: index}" ></ng-container>
                </a>

                <button class="button"
                        aria-controls="questionContainer"
                        (click)="onClickAnswer(answer)"
                        tkuiKeyboardAccess
                        *ngIf="!answer.link">
                  <ng-container *ngTemplateOutlet="innerButton; context:{answer: answer, index: index}" ></ng-container>
                </button>

              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentSection.type === 'textOption'">
        <div class="section">
          <tkui-video-teaser *ngIf="currentSection.videoName"
                             [videoName]="currentSection.videoName"
                             [videoTitle]="currentSection.videoTitle"></tkui-video-teaser>

          <tkui-textblock [text]="textOption"></tkui-textblock>

          <div class="grid-root-container">
            <div class="proceed-button-container standard-grid-item">
              <tkui-button [label]="currentSection.proceedButtonLabel"
                           aria-controls="questionContainer"
                           [ariaLabel]="currentSection.proceedButtonAriaLabel || currentSection.proceedButtonLabel"
                           (click)="onClickAnswer(null)"></tkui-button>
            </div>
          </div>
        </div>
      </ng-container>


  </div>
</main>

<ng-template #innerButton let-answer="answer" let-index="index">
  <span class="button__letter" [attr.aria-label]="'Antwortmöglichkeit ' + (index + 1)">{{'ABCDE'.charAt(index)}}</span>
  <span class="button__label">{{answer.label}}</span>
</ng-template>
