import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectStateService} from '../../services/project-state.service';
import {AntiSedentaryEvaluationService} from '../../services/anti-sedentary-evaluation.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-main-anti-sedentary',
  templateUrl: './main-anti-sedentary.component.html',
  styleUrls: ['./main-anti-sedentary.component.scss']
})
export class MainAntiSedentaryComponent implements OnInit, OnDestroy {

  public content: any;
  public currentSectionNumber = 0;
  public imageName: string;
  public options = {
    activityEvaluation: null,
    Q12_Aa: null,
    Q12_Ab: null,
  };
  public restartLink = this.projectState.pathAntiSedentary;

  private changesSubscription: Subscription;
  private queryParamsSubscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly projectState: ProjectStateService,
    private readonly antiSedentaryEvaluationService: AntiSedentaryEvaluationService,
  ) {
    this.antiSedentaryEvaluationService.reset();
  }

  ngOnInit(): void {
    this.content = this.activatedRoute.snapshot.data.content;
    this.observeQueryParams();
    this.observeChanges();
  }

  ngOnDestroy() {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  public onAnswer(answer: any) {
    this.antiSedentaryEvaluationService.applyAnswer(answer?.id);
    this.gotoSection(this.currentSectionNumber + 1);
  }

  private gotoSection(nextSectionNumber) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {section: nextSectionNumber},
      queryParamsHandling: 'merge'
    });
  }

  private updateHeaderImage() {
    this.imageName = this.content.header[this.antiSedentaryEvaluationService.activityImageOption];
  }

  private observeChanges() {
    this.changesSubscription = this.antiSedentaryEvaluationService.changes$.subscribe(() => {
      this.options.activityEvaluation = this.antiSedentaryEvaluationService.activityTextOption;
      this.options.Q12_Aa = this.antiSedentaryEvaluationService.linkOption;
      this.options.Q12_Ab = this.antiSedentaryEvaluationService.linkOption;
      this.updateHeaderImage();
    });
  }

  private observeQueryParams() {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(({section}) => {
      this.currentSectionNumber = Number(section) || 0;

      if (this.currentSectionNumber === 0) {
        this.antiSedentaryEvaluationService.reset();
      }

      const unansweredQuestionThatShouldBeAnswered = this.content.sections
        .slice(0, this.currentSectionNumber + 1) // sub-array with questions which already have been past
        .map((content, i) => ({content, i})) // store section-index
        .filter(item => !item.content.type) // filter for questions (questions currently are the default and have no type)
        .find(item => !this.antiSedentaryEvaluationService.isQuestionAnswered(item.content.id)); // search for first unanswered question

      if (unansweredQuestionThatShouldBeAnswered) {
        this.gotoSection(unansweredQuestionThatShouldBeAnswered.i);
      }
    });
  }

}
