<tkui-global-frame-simple
  [headerLogoMobileName]="headerLogoMobileName"
  [headerLogoName]="headerLogoName"
  [link]="logoLink"
></tkui-global-frame-simple>

<div class="stress-navigator" *ngIf="!isLoading">
  <router-outlet></router-outlet>
</div>

<div class="loader-wrapper" *ngIf="isLoading">
  <div class="loader">
    Die Anwendung wird geladen...
  </div>
</div>

<div class="footer">© Techniker Krankenkasse<span *ngIf="copyrightYear"> {{copyrightYear}}</span>, <a href="https://www.tk.de/techniker/impressum-2013112">Impressum</a></div>
