import {HttpClient} from '@angular/common/http';
import {Component, isDevMode, OnInit} from '@angular/core';
import {TkuiImageService, TkuiMediaService} from 'tk-ui-components';
import {Project, ProjectStateService} from './services/project-state.service';

@Component({
  selector: 'tk-stress-navigator',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tk-stress-navigator';
  headerLogoMobileName = './assets/images/tk-logo--noText.svg';
  headerLogoName = './assets/images/tk-logo-xs.svg';
  logoLink = '';

  isLoading = true;
  copyrightYear: string;

  constructor(
    private readonly imageService: TkuiImageService,
    private readonly mediaService: TkuiMediaService,
    private readonly http: HttpClient,
    private readonly projectStateService: ProjectStateService,
  ) {
  }

  ngOnInit() {
    this.initConfig();
    this.initCopyrightYear();
  }

  private initConfig() {
    this.http.get('./assets/config.json').toPromise()
      .then((config: any) => {
        this.imageService.setBaseImagesUrl(config.MEDIA_SERVER);
        this.mediaService.setBaseMediaUrl(config.MEDIA_SERVER);
        this.logoLink = config.LOGO_LINK;
      })
      .catch(error => {
        if (isDevMode()) {
          console.error(error);
        }
      })
      .finally(() => this.isLoading = false);
  }

  private initCopyrightYear() {
    const state: Project = this.projectStateService.activeProject;
    switch (state) {
      case Project.RAUS_UND_LOS:
        this.copyrightYear = '2021';
        break;
      case Project.STRESS_NAVIGATOR:
        this.copyrightYear = '2020';
        break;
    }
  }

}
