import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {

  @Input() max: number;
  @Input() current: number;

  public percentCurrent: number;
  public percentPast: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    try {
      this.percentCurrent = Math.max(0, (this.current / this.max * 100));
      this.percentPast = Math.max(0, ((this.current - 1) / this.max * 100));
    } catch (err) {
      this.percentCurrent = 0;
      this.percentPast = 0;
    }
  }

}
