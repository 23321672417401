<app-header [title]="content.header.title"
            [imageName]="content.header.imageName"
            [restartLabel]="content.header.restartLabel"
            [restartLink]="restartLink"
            [isRestartLinkVisible]="currentSectionNumber !== '1'"></app-header>

<app-sections [sections]="content.sections"
              [currentSectionNumber]="currentSectionNumber"
              (answer)="onAnswer($event)"></app-sections>

